const baseFields = [
  {
    key: 'corporationName',
    label: 'Entity Name',
    type: 'text-input',
    required: true,
    value: null,
  },
  {
    key: 'address',
    label: 'Corporation Address',
    type: 'custom',
    required: false,
    value: null,
  },
  {
    key: 'businessNumber',
    label: 'Business Number',
    type: 'text-input',
    required: false,
    value: null,
  },
  {
    key: 'businessNumberBackup',
    label: 'Business Number Backup',
    type: 'custom',
    required: false,
    value: null,
  },
  {
    key: 'financialYearEndOn',
    label: 'Financial Year End',
    type: 'datepicker',
    required: false,
    value: null,
  },
  {
    key: 'authorizedShareCapital',
    label: 'Authorized Share Capital',
    type: 'number-input',
    required: false,
    value: null,
  },
  {
    key: 'status',
    label: 'Status',
    type: 'select',
    options: [
      {
        key: 'active',
        name: 'active',
      },
      {
        key: 'inactive',
        name: 'inactive',
      },
    ],
    required: false,
    value: 'active',
  },
  {
    key: 'jurisdiction',
    label: 'Jurisdiction',
    type: 'custom',
    required: false,
    value: null,
  },
]

const incorporationFields = [
  {
    key: 'incorporationNumber',
    label: 'Incorporation Number',
    type: 'text-input',
    customClass: 'is-fullwidth',
    required: false,
    value: null,
  },
  {
    key: 'incorporationDate',
    label: 'Incorporation Date',
    type: 'datepicker',
    customClass: 'is-fullwidth ml-3',
    required: false,
    value: null,
  },
]

const annualReportFields = [
  {
    key: 'annualReportFilingOn',
    label: 'Annual Report Filing Date',
    type: 'datepicker',
    required: false,
    value: null,
  },
]

const annualResolutionsFields = [
  {
    key: 'annualResolutionOn',
    label: 'Current as at',
    type: 'datepicker',
    required: false,
    value: null,
  },
  {
    key: 'nextAnnualResolutionOn',
    label: 'Next meeting Date',
    type: 'datepicker',
    required: false,
    value: null,
  },
]

export {
  baseFields,
  incorporationFields,
  annualReportFields,
  annualResolutionsFields,
}
