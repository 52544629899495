const defaultAddressObject = {
  addressType: null,
  streetNumber: null,
  route: null,
  subpremise: null,
  locality: null,
  administrativeAreaLevel1: null,
  country: null,
  postalCode: null,
}

export {
  defaultAddressObject,
}
