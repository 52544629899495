<template>
  <div class="address-form-fields">
    <b-field label="Address Type" custom-class="required" class="is-fullwidth mb-4" v-if="!defaultAddressType">
      <div class="control has-icons-right">
        <b-select v-model="addressForm.addressType" :disabled="!editable" required>
          <option
            v-for="(type, index) in addressTypes"
            :key="index"
            :value="type"
          >{{ type }}</option>
        </b-select>
      </div>
    </b-field>
    <div class="field is-horizontal mb-4">
      <b-field label="Street Address" custom-class="required" class="is-fullwidth mb-0">
        <div class="control has-icons-right">
          <GoogleAutocomplete
            v-model="streetAddress"
            :editable="editable"
            :id="`address-${index}-autocomplete`"
            @placeChanged="autocompleted"
            v-if="hasGoogleAutoComplete"
          ></GoogleAutocomplete>
          <b-input v-model="streetAddress" :disabled="!editable" v-else></b-input>
        </div>
      </b-field>
      <b-field label="Unit, Apt., Etc." class="is-fullwidth ml-3">
        <b-input placeholder="optional" v-model="addressForm.subpremise" ref="subpremiseInput" :disabled="!editable"></b-input>
      </b-field>
    </div>
    <div class="field is-horizontal mb-4">
      <b-field label="City" custom-class="required" class="is-fullwidth mb-0">
        <div class="control has-icons-right">
          <b-input v-model="addressForm.locality" ref="localityInput" :disabled="!editable" required></b-input>
        </div>
      </b-field>
      <b-field label="Province / State" custom-class="required" class="is-fullwidth ml-3">
        <div class="control has-icons-right">
          <b-input v-model="addressForm.administrativeAreaLevel1" ref="administrativeAreaLevel1Input" :disabled="!editable" required></b-input>
        </div>
      </b-field>
    </div>
    <div class="field is-horizontal">
      <b-field label="Country" custom-class="required" class="is-fullwidth mb-0">
        <div class="control has-icons-right">
          <b-input v-model="addressForm.country" ref="countryInput" :disabled="!editable" required ></b-input>
        </div>
      </b-field>
      <b-field label="Postal Code" custom-class="required" class="is-fullwidth ml-3">
        <b-input v-model="addressForm.postalCode" :disabled="!editable"></b-input>
      </b-field>
    </div>
  </div>
</template>
<script>
import GoogleAutocomplete from '@components/input/google-autocomplete.vue'

const AddressTypes = ['billing', 'delivery', 'mailing', 'primary', 'residential']
const RequiredFields = ['addressType', 'administrativeAreaLevel1', 'country', 'locality', 'route', 'postalCode']

export default {
  components: {
    GoogleAutocomplete,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    address: {
      type: Object,
      required: true,
      validator(address) {
        return RequiredFields.every((field) => Object.hasOwnProperty.call(address, field))
      },
    },
    defaultAddressType: {
      type: String,
      validator(type) {
        return AddressTypes.includes(type)
      },
    },
    hasGoogleAutoComplete: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      addressTypes: Object.freeze(AddressTypes),
      addressForm: {},
    }
  },
  computed: {
    streetAddress: {
      get() {
        // filter out null/undefined in the array, then join with space into string
        return [this.addressForm.streetNumber, this.addressForm.route].filter((string) => string).join(' ')
      },
      set(value) {
        this.addressForm.streetNumber = ''
        this.addressForm.route = value
      }
    },
    isCompleted() {
      return RequiredFields.every((field) => this.addressForm[field])
    },
    fullAddress() {
      return `${this.streetAddress}, ${this.addressForm.locality} ${this.addressForm.administrativeAreaLevel1}, ${this.addressForm.country}`
    }
  },
  watch: {
    address: {
      handler(value) {
        this.addressForm = value
        if (this.defaultAddressType) this.addressForm.addressType = this.defaultAddressType
      },
      immediate: true,
    },
    isCompleted: {
      handler(value) {
        this.$emit('addressFormCompleted', value)
      },
      immediate: true
    },
  },
  methods: {
    autocompleted(addressData) {
      const {
        route, // street name
        country,
        locality, // city
        subpremise, // unit number
        latitude,
        longitude,
        postal_code: postalCode,
        street_number: streetNumber,
        postal_code_suffix: postalCodeSuffix, // US additional postal code
        administrative_area_level_1: administrativeAreaLevel1,
      } = addressData
      const postal = postalCodeSuffix ? `${postalCode} - ${postalCodeSuffix}` : postalCode
      this.addressForm.streetNumber = streetNumber
      this.addressForm.route = route
      this.addressForm.postalCode = postal || ''
      this.addressForm.locality = locality
      this.addressForm.administrativeAreaLevel1 = administrativeAreaLevel1
      this.addressForm.country = country
      this.addressForm.subpremise = subpremise || ''
      this.addressForm.latitude = latitude
      this.addressForm.longitude = longitude
      this.validateAddressFields()
    },
    // due to Buefy input issues, we will need to validate the required input fields
    // https://github.com/buefy/buefy/issues/3752
    // checkHtml5Validity() from b-input always returns false, even if there is an input value,
    // so the easiest solution is to focus each of the fields to auto validate
    validateAddressFields() {
      this.$refs.localityInput.focus()
      this.$refs.administrativeAreaLevel1Input.focus()
      this.$refs.countryInput.focus()
      this.$refs.subpremiseInput.focus()
    },
    onAddressDeletion() {
      this.$emit('onAddressDeletion')
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/addy-variables.scss';
.address-form-fields {
  .field {
    ::v-deep .label.required::before {
      content: '* ';
      color: red;
    }
    ::v-deep .select {
      width: 100%;
      select {
        width: 100%;
      }
    }
    ::v-deep .icon.is-left .fa {
      color: var(--addy-blue-light-1);
    }
  }
}
</style>
