import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Fetch corporations
 * @param {String} accountId
 * @return {Promise}
 */
export const fetchCorporations = (accountId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations?account_id=${accountId}`))
}

/**
 * Fetch legal document types
 * @return {Promise}
 */
export const fetchLegalDocumentTypes = () => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/base_file_types`))
}

/**
 * Fetch paginated corporation CSR
 * @param {String} corporationId
 * @param {Object} payload // payload: { page_number, per_size, sort_field, sort_order, transaction_filter, status_filter, start_date, end_date }
 * @return {Promise}
 */
export const fetchPaginatedCSR = (corporationId, payload) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/csr`), { params: payload })
}

/**
 * request CSR download
 * @param {String} corporationId
 * @param {Object} payload // payload: { ledger_type }
 * @return {Promise}
 */
export const csrDownload = (corporationId, payload) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/csr_download`), { params: payload })
}

/**
 * create new corporation
 * @param {Object} params
 * @return {Promise}
 */
export const createCorporation = (params) => {
  return axios.post(getImbyRoute('/api/addy_plus/v1/corporations'), { corporation: params })
}

/**
 * fetch details of corporation
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchCorporationDetails = (corporationId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}`))
}

/**
 * update corporation
 * @param {String} corporationId
 * @param {Object} formData
 * @return {Promise}
 */
export const updateCorporation = (corporationId, formData) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}`), formData)
}

/**
 * Fetch corporation executives
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchCorporationExecutives = (corporationId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/executives`))
}

/**
 * Create corporation executives
 * @param {String} corporationId
 * @param {Object} params
 * @return {Promise}
 */
export const createCorporationExecutive = (corporationId, params) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/executives`), params)
}

/**
 * Update corporation executives
 * @param {String} corporationId
 * @param {String} executiveId
 * @param {Object} params
 * @return {Promise}
 */
export const updateCorporationExecutive = (corporationId, executiveId, params) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/executives/${executiveId}`), params)
}

/**
 * Update corporation executives
 * @param {String} corporationId
 * @param {String} executiveId
 * @return {Promise}
 */
export const deleteCorporationExecutive = (corporationId, executiveId) => {
  return axios.delete(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/executives/${executiveId}`))
}

/**
 * Mass upload data room files / delete file
 * @param {String} corporationId
 * @param {Object} formData
 * @param {Function} onUploadProgress
 * @return {Promise}
 */
export const handleCorporateDataRoomFiles = (corporationId, formData, onUploadProgress) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/data_room`), formData, {
    onUploadProgress
  })
}

/**
 * Fetch data room files
 * @param {String} corporationId
 * @param {String} department
 * @return {Promise}
 */
export const fetchCorporateDataRoomFiles = (corporationId, department) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/data_room?department=${department}`))
}

/**
 * Fetch document categories
 * @param {String} corporationId
 * @param {String} department
 * @return {Promise}
 */
export const fetchDocumentCategories = (corporationId, department) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/data_room/document_categories?department=${department}`))
}

/**
 * add a new document category
 * @param {String} corporationId
 * @param {String} category
 * @param {String} type
 * @param {String} department
 * @return {Promise}
 */
export const handleDocumentCategoryCreation = (corporationId, category, type, department) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/data_room/document_categories`), { category, type, department })
}

/**
 * Export Suitability Report
 * @param {String} corporationId
 * @return {Promise}
 */
export const exportSuitabilityReport = (corporationId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/suitability_report`), { responseType: 'blob', downloadFile: true })
}
