<template>
  <b-input
    required
    v-model="computedValue"
    name="streetAddress"
    :id="id"
    autocomplete="off"
    :disabled="!editable"
  />
</template>
<script>
import { Loader } from '@googlemaps/js-api-loader'

const ADDRESS_TYPE_MAP = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'short_name',
  locality: 'short_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name',
  postal_code_suffix: 'short_name',
}

export default {
  data() {
    return {
      newValue: this.value,
      autocomplete: null,
    }
  },
  props: {
    value: {
      type: String,
    },
    id: {
      type: String,
      default: 'autocomplete',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
  },
  watch: {
    value(value) {
      this.newValue = value
    },
    formField() {
      this.newValue = this.formField.value
    },
  },
  mounted() {
    this.initGoogleMaps()
  },
  methods: {
    checkGoogleMapsStatus() {
      this.checkingInterval = setInterval(() => {
        if (window.google) {
          this.googleMapsLoaded = true
        }
      }, 500)
    },
    initGoogleMaps() {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
      })
      loader.load().then(() => {
        this.initAutocomplete()
      })
    },
    initAutocomplete() {
      const autocompleteElement = document.getElementById(this.id)
      /* eslint-disable-next-line */
      this.autocomplete = new google.maps.places.Autocomplete(
        autocompleteElement, {
          componentRestrictions: { country: ['us', 'ca'] },
          fields: ['address_components', 'geometry'],
          types: ['address'],
        })
      this.autocomplete.addListener('place_changed', this.onPlaceChanged)
    },
    onPlaceChanged() {
      const place = this.autocomplete.getPlace() // Get the place details from the autocomplete object
      this.$emit('placeChanged', this.formatPayload(place))
    },
    formatPayload(place) {
      const returnData = {}
      if (place.address_components) {
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of place.address_components) {
          const addressType = component.types[0]
          if (ADDRESS_TYPE_MAP[addressType]) {
            const val = component[ADDRESS_TYPE_MAP[addressType]]
            returnData[addressType] = val
          }
        }
      }
      const geoLocation = place.geometry?.location
      const lat = geoLocation?.lat()
      const lng = geoLocation?.lng()
      lat && (returnData.latitude = lat)
      lng && (returnData.longitude = lng)
      return returnData
    },
  },
}
</script>
