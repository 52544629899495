<template>
  <div class="corporation-form">
    <FormFieldsComponent
      :formFields="baseFields"
      :editable="canUpdate"
      ref="baseFields"
      @onCompletionChange="onBaseFieldsCompletionChange"
    >
      <template #address="{}">
        <AddressFormFields
          :editable="canUpdate"
          :address="customFields.address"
          defaultAddressType="primary"
          :hasGoogleAutoComplete="true"
          @addressFormCompleted="(status) => addressFormCompleted = status"
        />
      </template>
      <template #jurisdiction="{ field }">
        <b-field :label="field.label">
          <b-select
            placeholder="Select province"
            v-model="customFields.jurisdiction"
            :id="field.key"
            :disabled="!canUpdate">
            <option
              v-for="province in provinces"
              :key="province.code"
              :value="province.name"
            >{{province.name}}</option>
          </b-select>
        </b-field>
      </template>
      <template #businessNumberBackup="{ field }">
        <label class="label">{{ field.label }}</label>
        <b-field class="file" :class="{'has-name': !!customFields.businessNumberBackup}">
          <b-upload v-model="customFields.businessNumberBackup" :disabled="!canUpdate" :id="field.key" class="file-label" required validationMessage="Please select a file">
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Click to upload</span>
            </span>
            <span class="file-name" v-if="customFields.businessNumberBackup">{{ customFields.businessNumberBackup.name }}</span>
          </b-upload>
        </b-field>
        <a v-if="!!fields && fields.hasBusinessNumberBackup" @click="fetchBusinessNumberBackup" class="has-text-primary is-size-6">View business number backup</a>
      </template>
    </FormFieldsComponent>
    <FormFieldsComponent
      class="field is-horizontal mb-0"
      :formFields="incorporationFields"
      ref="incorporationFields"
      :editable="canUpdate"
      :marginless="true"
    ></FormFieldsComponent>
    <section id="annual-report" class="my-6">
      <h2 class="section-title has-text-weight-semibold pb-4 has-text-blue">Annual Report Filings</h2>
      <FormFieldsComponent :formFields="annualReportFields" ref="annualReportFields" :editable="canUpdate"></FormFieldsComponent>
    </section>
    <section id="annual-resolutions" class="my-6">
      <h2 class="section-title has-text-weight-semibold pb-4 has-text-blue">Annual Resolutions</h2>
      <FormFieldsComponent :formFields="annualResolutionsFields" ref="annualResolutionsFields" :editable="canUpdate"></FormFieldsComponent>
    </section>
    <slot v-if="canUpdate" name="bottom">
      <b-button @click="onSave" type="is-blue" :disabled="!formCompleted">Save</b-button>
    </slot>
  </div>
</template>
<script>
import FormFieldsComponent from '@components/form/form-fields'
import AddressFormFields from '@components/form/address-form-fields.vue'
import { baseFields, incorporationFields, annualReportFields, annualResolutionsFields } from '@utils/data/addy_plus_corporation_fields'
import CanadaProvinces from '@utils/data/canada_provinces'
import { defaultAddressObject } from '@utils/data/addy_plus_address_fields.js'
import { fetchDocUrl } from '@/api/common'
import { previewFile } from '@utils/common-methods/common'

export default {
  components: {
    FormFieldsComponent,
    AddressFormFields,
  },
  data() {
    return {
      baseFields,
      incorporationFields,
      annualReportFields,
      annualResolutionsFields,
      provinces: Object.freeze(CanadaProvinces),
      customFields: {
        jurisdiction: null,
        address: JSON.parse(JSON.stringify(defaultAddressObject)),
        businessNumberBackup: null,
      },
      baseFieldsCompleted: false,
      addressFormCompleted: false,
    }
  },
  props: {
    fields: Object,
    canUpdate: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formCompleted() {
      return this.addressFormCompleted && this.baseFieldsCompleted
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  watch: {
    fields() {
      this.autoPopulateFields()
    },
  },
  methods: {
    onSave() {
      this.$emit('onSave', {
        ...this.$refs.baseFields.formData,
        ...this.$refs.incorporationFields.formData,
        ...this.$refs.annualReportFields.formData,
        ...this.$refs.annualResolutionsFields.formData,
        address: this.customFields.address,
        jurisdiction: this.customFields.jurisdiction,
        businessNumberBackup: this.customFields.businessNumberBackup,
      })
    },
    fetchBusinessNumberBackup() {
      fetchDocUrl({
        hashid: this.corporationId,
        model_name: 'Corporation',
        document_type: 'business_number_backup',
      }).then((res) => {
        if (res.success) previewFile(res.data.url)
      })
    },
    onBaseFieldsCompletionChange(isCompleted) {
      this.baseFieldsCompleted = isCompleted
    },
    autoPopulateFields() {
      this.dataPopulation('baseFields')
      this.dataPopulation('incorporationFields')
      this.dataPopulation('annualReportFields')
      this.dataPopulation('annualResolutionsFields')
      this.populateCustomFields()
    },
    dataPopulation(ref) {
      const copy = JSON.parse(JSON.stringify(this[ref]))
      copy.forEach((field) => {field.value = this.fields[field.key]})
      this[ref] = copy
    },
    populateCustomFields() {
      this.customFields.address = this.fields.address
      this.customFields.jurisdiction = this.fields.jurisdiction
    },
    autocompleted(addressData) {
      const {
        route, // street name
        country,
        locality, // city
        subpremise, // unit number
        postal_code: postalCode,
        street_number: streetNumber,
        postal_code_suffix: postalCodeSuffix, // US additional postal code
        administrative_area_level_1: province,
      } = addressData
      const streetAddress = streetNumber ? `${streetNumber} ${route}` : route
      const postal = postalCodeSuffix ? `${postalCode} - ${postalCodeSuffix}` : postalCode
      this.$refs.baseFields.formData.corpStreetName = streetAddress
      this.$refs.baseFields.formData.corpPostal = postal || null
      this.$refs.baseFields.formData.corpCity = locality
      this.$refs.baseFields.formData.corpProvince = province
      this.$refs.baseFields.formData.corpCountry = country
      this.$refs.baseFields.formData.corpUnitNumber = subpremise || null
    },
  },
}
</script>
<style lang="scss" scoped>
.corporation-form {
  max-width: 800px;
  .section-title {
    font-size: 20px;
  }
}
</style>
