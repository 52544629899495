<template>
  <div>
    <div
      :class="`is-relative ${marginless ? '' : 'mb-4'} ${field.customClass}`"
      v-for="field in formFields"
      :key="field.key"
    >
      <b-field
        v-if="field.type === 'text-input'"
        :label="field.label"
        :custom-class="field.required ? 'required' : ''"
      >
        <b-input
          :id="field.key"
          v-model="formData[field.key]"
          :name="field.key"
          :required="field.required"
          :disabled="!editable"/>
      </b-field>
      <b-field
        v-else-if="field.type === 'tag-input'"
        :label="field.label"
        :custom-class="field.required ? 'required' : ''"
      >
        <b-taginput
          :id="field.key"
          v-model="formData[field.key]"
          :name="field.key"
          :required="field.required"
          :icon="field.icon || ''"
          :disabled="!editable"
          placeholder="Add a tag"
          aria-close-label="Delete this tag"
          ellipsis>
        </b-taginput>
      </b-field>
      <b-field
        v-else-if="field.type === 'number-input'"
        :label="field.label"
        :custom-class="field.required ? 'required' : ''"
      >
        <b-input
          :id="field.key"
          v-model.number="formData[field.key]"
          :name="field.key"
          :required="field.required"
          type="number"
          :step="field.step || 1"
          :disabled="!editable"/>
      </b-field>
      <b-field
        v-else-if="field.type === 'select'"
        :label="field.label"
        :custom-class="field.required ? 'required' : ''"
      >
        <b-select
          :id="field.key"
          :placeholder="`Select ${field.key}`"
          v-model="formData[field.key]"
          :required="field.required"
          :disabled="!editable"
        >
          <option
            v-for="option in field.options"
            :key="option.key"
            :value="option.key"
          >{{option.name}}</option>
        </b-select>
      </b-field>
      <b-field
        v-else-if="field.type === 'text-area'"
        :label="field.label"
        :custom-class="field.required ? 'required' : ''"
      >
        <b-input
          v-model="formData[field.key]"
          :name="field.key"
          :required="field.required"
          type="textarea"
          :disabled="!editable"/>
      </b-field>
      <b-field v-else-if="field.type === 'checkbox'">
        <b-checkbox v-model="formData[field.key]" :disabled="!editable">{{field.label}}</b-checkbox>
      </b-field>
      <template v-else-if="field.type === 'datepicker'">
        <b-field :label="field.label" :class="`${marginless ? 'mb-0' : ''}`">
          <b-datepicker
            editable
            :id="field.key"
            :disabled="!editable"
            v-model="formData[field.key]"
            locale="en-CA"
            placeholder="YYYY-MM-DD"
            icon="calendar-week"
          ></b-datepicker>
        </b-field>
        <span v-if="editable" class="icon reset is-right is-clickable" @click="clearDate(field.key)">
          <i class="fa fa-times-circle"></i>
        </span>
      </template>
      <!-- the fallback is for a custom form field; field.type === 'custom' -->
      <template v-else>
        <slot :name="field.key" :field="field" :formData="formData"></slot>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {},
    }
  },
  props: {
    formFields: {
      type: Array,
      required: true,
      validator: (fields) => ['key', 'label', 'type', 'required'].every((key) => Object.keys(fields[0]).includes(key)),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    marginless: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    requiredFields() {
      return this.formFields.filter((field) => field.required).map((field) => field.key)
    },
    isCompleted() {
      const isIncomplete = this.requiredFields.some((field) => !this.formData[field])
      return !isIncomplete
    },
  },
  watch: {
    formFields: {
      handler(value) {
        const obj = {}
        value.map((field) => {
          // convert '2022-12-01' => '2022/12/01' to avoid incorrect date display
          obj[field.key] = (field.type === 'datepicker' && field.value) ? new Date(field.value.replaceAll('-', '/')) : field.value
        })
        this.formData = obj
      },
      immediate: true,
    },
    isCompleted(status) {
      this.$emit('onCompletionChange', status)
    },
  },
  methods: {
    clearDate(key) {
      if (!this.formData[key]) return
      this.formData[key] = null
    },
  },
}
</script>
<style lang="scss" scoped>
.field {
  ::v-deep .label.required::before {
    content: '* ';
    color: red;
  }
  ::v-deep .select {
    width: 100%;
    select {
      width: 100%;
    }
  }
  ::v-deep .icon.is-left .fa {
    color: var(--addy-blue-light-1);
  }
}
.icon.reset {
  position: absolute;
  right: 8px;
  bottom: 0;
  height: 40px;
  .fa {
    font-size: 16px;
    color: var(--addy-blue-light-1);
  }
}
.b-checkbox.checkbox {
    font-size: 16px;
}
</style>
